import 'shims';

import { initStrings } from 'LoaderStrings';

import { isWebRtcSupported } from './BrowserSupport';
import { EmbeddedRefAppController } from './EmbeddedRefAppController';
import EmbeddedRefAppUI from './EmbeddedRefAppUI';

let ctrl = null;

export default function init({ element, config }) {
  if (!isWebRtcSupported())
    return false;

  const {
    wsSipURI,
    toSipURI,

    fetchLocationURL = null,
    fetchRTCConfigurationURL = null,
    fetchIceServersURL = null,

    fromName,
    email,
  } = config;

  const appConfig = {
    wsSipURI,
    toSipURI,

    fetchLocationURL,
    fetchRTCConfigurationURL,
    fetchIceServersURL,

    fromName,
    email,
  };

  if (!ctrl) {
    initStrings();

    ctrl = new EmbeddedRefAppController({
      appConfig,
    });

    element.appendChild(
      <EmbeddedRefAppUI ctrl={ctrl} />
    );

    ctrl.start();
  } else {
    ctrl.setAppConfig(appConfig);
  }

  return ctrl;
}
