import { AppController } from './AppController';
import { CallController } from './CallController';
import WindowEventHandler from './WindowEventHandler';

export class RefAppController extends AppController {
  constructor({
    appConfig,
    localStorageKey,
    storedFields = null,
    handleHash = true,
  }) {
    super({
      appConfig,
      localStorageKey,
      storedFields: storedFields || [
        'wsSipURI',
        'toSipURI',
        'fromSipURI',
        'fromName',
      ],
    });

    this._debugMode = false;

    this.callController = new CallController({
      instanceName: 'ReferenceApp',
    });
    this.addCallController(this.callController);

    this.callController.on('update', () => this.emit('update'));

    this.windowEventHandler = new WindowEventHandler({ ctrl: this, handleHash });

    this.loadStoredParams();
  }

  start() {
    this.callController.start();
  }

  connect(formData) {
    this.initiateConnection(this.callController, formData, formData => this._getConnectParams(formData));
  }

  setDebugMode(debugMode) {
    this._debugMode = debugMode;
    this.callController.setDebugMode(debugMode);
  }

  get debugMode() {
    return this._debugMode;
  }

  createFormDefaults(appConfig) {
    return {
      wsSipURI: appConfig.wsSipURI,
      fromName: '',
      fromSipURI: appConfig.fromSipURI,
      toSipURI: appConfig.toSipURI,

      fetchLocationURL: appConfig.fetchLocationURL,
      fetchRTCConfigurationURL: appConfig.fetchRTCConfigurationURL,
      fetchIceServersURL: appConfig.fetchIceServersURL,
    };
  }

  _getConnectParams(formData) {
    const ret = {
      wsSipURI: formData.wsSipURI,
      fetchLocationURL: this._appConfig.fetchLocationURL,
      fetchRTCConfigurationURL: this._appConfig.fetchRTCConfigurationURL,
      fetchIceServersURL: this._appConfig.fetchIceServersURL,

      toSipURI: formData.toSipURI,
      fromSipURI: formData.fromSipURI || this._appConfig.fromSipURI,
    };

    if (formData.fromName)
      ret.fromName = formData.fromName;

    return ret;
  }
}
