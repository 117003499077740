export default {
  BLANK                         : '\u00a0',
  FACEBOOK                      : '\ue000',
  TWITTER                       : '\ue001',
  LINKEDIN                      : '\ue002',
  QUESTION_CIRCLE               : '\ue003',
  INFO_CIRCLE                   : '\ue004',
  ALERT_CIRCLE                  : '\ue005',
  CHECK_CIRCLE                  : '\ue006',
  CROSS_CIRCLE                  : '\ue007',
  PLUS_CIRCLE                   : '\ue008',
  RADIO_UNCHECKED               : '\ue009',
  RADIO_CHECKED                 : '\ue00a',
  SOLID_CHECKBOX_UNCHECKED      : '\ue00b',
  SOLID_CHECKBOX_CHECKED        : '\ue00c',
  CHECKBOX_UNCHECKED            : '\ue00d',
  CHECKBOX_INDETERMINATE        : '\ue00e',
  CHECKBOX_CHECKED              : '\ue00f',
  STAR_UNCHECKED                : '\ue010',
  STAR_CHECKED                  : '\ue011',
  STAR2_UNCHECKED               : '\ue012',
  STAR2_CHECKED                 : '\ue013',
  CHECK                         : '\ue014',
  ARROW_LEFT                    : '\ue015',
  ARROW_RIGHT                   : '\ue016',
  PLUS                          : '\ue017',
  PLAY                          : '\ue018',
  EDIT                          : '\ue019',
  LOCK                          : '\ue01a',
  HOME                          : '\ue01b',
  SEARCH                        : '\ue01c',
  MENU                          : '\ue01d',
  CROSS                         : '\ue01e',
  FOLDER                        : '\ue01f',
  EXPORT                        : '\ue020',
  TAG                           : '\ue021',
  CALENDER                      : '\ue022',
  CONF_MERGE                    : '\ue023',
  COMMENT                       : '\ue024',
  MAKE_CALL                     : '\ue025',
  CALL_DISCONNECTED             : '\ue026',
  PLAY_CIRCLE                   : '\ue027',
  RESULT_FIRST                  : '\ue028',
  RESULT_PREVIOUS               : '\ue029',
  RESULT_NEXT                   : '\ue02a',
  RESULT_LAST                   : '\ue02b',
  TRASH                         : '\ue02c',
  DETAILS                       : '\ue02d',
  UNHOLD                        : '\ue02e',
  HOLD                          : '\ue02f',
  MIC                           : '\ue030',
  MIC_OFF                       : '\ue031',
  DIALPAD                       : '\ue032',
  SETTINGS                      : '\ue033',
  VOLUME_OFF                    : '\ue034',
  VOLUME_UP                     : '\ue035',
  VOLUME_ZERO                   : '\ue036',
  VOLUME_HALF                   : '\ue037',
  VOLUME_FULL                   : '\ue038',
  CONF_UNLOCK                   : '\ue039',
  CONF_LOCK                     : '\ue03a',
  HAND                          : '\ue03b',
  HAND_SELECTED                 : '\ue03c',
  HOST                          : '\ue03d',
  CALL_END                      : '\ue03e',
  RECORDING                     : '\ue03f',
  RECORDING_OFF                 : '\ue040',
  MUSIC                         : '\ue041',
  MUSIC_OFF                     : '\ue042',
  MUTE_LOCKED_UNMUTED           : '\ue043',
  MUTE_LOCKED_MUTED             : '\ue044',
  REFRESH                       : '\ue045',
  SEND                          : '\ue046',
  ARROW_DOUBLE_LEFT             : '\ue047',
  ARROW_DOUBLE_RIGHT            : '\ue048',
  MENU_LEFT                     : '\ue049',
  MENU_RIGHT                    : '\ue04a',
  MENU_UP                       : '\ue04b',
  MENU_DOWN                     : '\ue04c',
  SORT_UNSORTED                 : '\ue04d',
  SORT_ASC                      : '\ue04e',
  SORT_DESC                     : '\ue04f',
  STOP_CIRCLE                   : '\ue050',
  METER_BAR_ALL                 : '\ue051',
  METER_BAR_1                   : '\ue052',
  METER_BAR_2                   : '\ue053',
  METER_BAR_3                   : '\ue054',
  METER_BAR_4                   : '\ue055',
  TEST_GLYPH                    : '\ue056',
  PLAY_CIRCLE_INVERSE           : '\ue057',
  STOP                          : '\ue058',
  SOLID_CHECKBOX_INDETERMINATE  : '\ue059',
  CELL_TOWER                    : '\ue05a',
  PLAYLIST_PLAY                 : '\ue05b',
};
