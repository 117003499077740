import stringPacks from 'stringPacks';

function merge(dest, src) {
  Object.keys(src).forEach(_ => {
    const val = src[_];
    if (val && typeof val === 'object') {
      merge(dest[_], val);
    } else {
      dest[_] = val;
    }
  });
}

const { baseLanguage } = stringPacks.config;
const { data } = stringPacks;

const strings = {};

Object.keys(data).forEach(packName => strings[packName] = data[packName][baseLanguage]);

export function initStrings(siteLanguage = null, overrides = null) {
  Object.keys(data).forEach(packName => {
    // override with siteLanguage strings
    if (siteLanguage && siteLanguage !== baseLanguage && typeof data[packName][siteLanguage] === 'object') {
      merge(strings[packName], data[packName][siteLanguage]);
    }

    // override with site string overrides
    if (overrides && overrides[packName] && typeof overrides[packName] === 'object') {
      merge(strings[packName], overrides[packName]);
    }
  });
}

export default strings;
