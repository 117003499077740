import validateEmail from 'Util/validateEmail';

import { RefAppController } from './RefAppController';
import Exceptions from './Exceptions';

export class EmbeddedRefAppController extends RefAppController {
  constructor({
    appConfig,
  }) {
    super({
      appConfig,
      localStorageKey: 'webCall_embeddedRefConnectParams',
      storedFields: [
        'fromName',
        'email',
      ],
      handleHash: false,
    });

    this._isConnectModalOpen = false;
  }

  startConnect() {
    this._isConnectModalOpen = true;
    this.emit('update');
  }

  cancelConnect() {
    this._isConnectModalOpen = false;
    this.emit('update');
  }

  connect(formData) {
    this._isConnectModalOpen = false;
    super.connect(formData);
  }

  get isConnectModalOpen() {
    return this._isConnectModalOpen;
  }

  createFormDefaults(appConfig) {
    return {
      fromName: appConfig.fromName || '',
      email: appConfig.email || '',
    };
  }

  _getConnectParams(formData) {
    const ret = {
      wsSipURI: this._appConfig.wsSipURI,
      fetchLocationURL: this._appConfig.fetchLocationURL,
      fetchRTCConfigurationURL: this._appConfig.fetchRTCConfigurationURL,
      fetchIceServersURL: this._appConfig.fetchIceServersURL,

      toSipURI: this._appConfig.toSipURI,
    };

    if (formData.fromName)
      ret.fromName = formData.fromName;

    if (formData.email && !validateEmail(formData.email))
      throw new Exceptions.ConnectionError('ERR_INVALID_EMAIL');

    ret.fromSipURI = formData.email
      ? `sip:${formData.email}`
      : 'sip:caller@invalid';

    return ret;
  }
}
