import { Cond, RefFormData, FormText } from 'Components/FormComponents';
import { hook, Hooks } from 'Components/Hooks';
import Modal from 'Components/Modal';

import {
  AppConnectButton,
  AppConnectionErrorModal,
} from './UIComponents';
import EmbeddedRefCallUI from './EmbeddedRefCallUI';

import s from './strings';

export default function EmbeddedRefAppUI({ ctrl }) {
  const connectionForm = new RefFormData();

  const hooks = new Hooks();
  ctrl.on('update', () => hooks.run(ctrl));

  const ctx = {
    hooks,
    ctrl,

    connectionForm,
  };

  let connectModal;

  const root = (
    <div
      class="pnlWebCallApp webcall-embedded"
    >
      <AppConnectionErrorModal ctx={ctx} anchored={false} />

      <div use:hook={hooks.hide('isConnected')}>
        <button type="button" class="btn btn-primary btn-startConnect" onclick={() => ctrl.startConnect()}>{s.lblWebCall}</button>
      </div>

      <Modal
        title={s.lblWebCall}
        small
        onClose={() => ctrl.cancelConnect()}
        ref={connectModal}
        footerButtons={[
          <AppConnectButton ctx={ctx} />,
          <button type="button" class="btn btn-primary" onclick={() => ctrl.cancelConnect()}>
            {s.lblCancel}
          </button>
        ]}
      >
        <FormText inline form={connectionForm} name="fromName" label={s.lblName} />
        <FormText inline form={connectionForm} type="email" name="email" label={s.lblEmail} />

        <div class="disconnected-menu">
          <Cond test={ctrl.enableSettings}>
            <button type="button" class="btn btn-link" onclick={() => ctrl.openSettings()}>{s.lblSettings}</button>
          </Cond>
        </div>
      </Modal>

      <EmbeddedRefCallUI ctrl={ctrl.callController} />
    </div>
  );

  hooks.add('isConnectModalOpen', val => {
    if (val)
      connectModal.show();
    else
      connectModal.hide();
  });

  hooks.add('formDefaults', values => connectionForm.setValues(values));

  return root;
}
